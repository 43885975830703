import * as React from 'react';

import Layout from '../containers/Layout/Layout';
import { Container, Seo, Margins, Alert } from '../components';
import { useTranslation } from '../hooks';

const Registration = ({ pageContext: { currentPath } }) => {
  const { t, te, locale } = useTranslation();

  return (
    <Layout>
      <Seo
        title={t('SEO.REGISTRATION.TITLE')}
        location={currentPath}
        lang={locale}
      />
      <Container>
        <Margins size="3x">
          {te('REGISTRATION.INDEX.INFO') && (
            <Alert>{t('REGISTRATION.INDEX.INFO')}</Alert>
          )}
          <iframe
            src="https://connectedserver.eu/ConnectedOnlineX/default.aspx?key=KiAWFTkP&lang=et"
            style={{ height: '800px', width: '100%' }}
            title={t('SEO.REGISTRATION.TITLE')}
          ></iframe>
        </Margins>
      </Container>
    </Layout>
  );
};

export default Registration;
